import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import '../generic-page.scss';
import './tier-list.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';
import lodash from 'lodash';
import {
  faAsterisk,
  faHandFist,
  faVirus,
  faFlask,
  faArrowUp,
  faArrowDown
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Card } from 'react-bootstrap';
import { IWWCharacter } from '../../modules/common/model/graphql-types';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { WWCharacter } from '../../modules/ww/common/components/ww-character';

interface IWWCharacterNodes {
  nodes: IWWCharacter[];
}

interface IWWCharacterEntry {
  allCharacters: IWWCharacterNodes;
}

interface IProps {
  data: IWWCharacterEntry;
}

const WWTierList: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: '4★', value: '4' },
          { label: '5★', value: '5' }
        ]
      },
      {
        key: 'element',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Aero',
            tooltip: 'Aero',
            image: (
              <StaticImage
                src="../../images/ww/icons/element_aero.png"
                width={24}
                alt="Aero"
              />
            )
          },
          {
            value: 'Electro',
            tooltip: 'Electro',
            image: (
              <StaticImage
                src="../../images/ww/icons/element_electro.png"
                width={24}
                alt="Electro"
              />
            )
          },
          {
            value: 'Fusion',
            tooltip: 'Fusion',
            image: (
              <StaticImage
                src="../../images/ww/icons/element_fusion.png"
                width={24}
                alt="Fusion"
              />
            )
          },
          {
            value: 'Glacio',
            tooltip: 'Glacio',
            image: (
              <StaticImage
                src="../../images/ww/icons/element_glacio.png"
                width={24}
                alt="Glacio"
              />
            )
          },
          {
            value: 'Havoc',
            tooltip: 'Havoc',
            image: (
              <StaticImage
                src="../../images/ww/icons/element_havoc.png"
                width={24}
                alt="Havoc"
              />
            )
          },
          {
            value: 'Spectro',
            tooltip: 'Spectro',
            image: (
              <StaticImage
                src="../../images/ww/icons/element_spectro.png"
                width={24}
                alt="Spectro"
              />
            )
          }
        ]
      },
      {
        key: 'weapon',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Broadblade',
            tooltip: 'Broadblade',
            image: (
              <StaticImage
                src="../../images/ww/icons/weapon_broadblade.png"
                width={24}
                alt="Broadblade"
              />
            )
          },
          {
            value: 'Gauntlets',
            tooltip: 'Gauntlets',
            image: (
              <StaticImage
                src="../../images/ww/icons/weapon_gauntlets.png"
                width={24}
                alt="Gauntlets"
              />
            )
          },
          {
            value: 'Pistols',
            tooltip: 'Pistols',
            image: (
              <StaticImage
                src="../../images/ww/icons/weapon_pistols.png"
                width={24}
                alt="Pistols"
              />
            )
          },
          {
            value: 'Rectifier',
            tooltip: 'Rectifier',
            image: (
              <StaticImage
                src="../../images/ww/icons/weapon_rectifier.png"
                width={24}
                alt="Rectifier"
              />
            )
          },
          {
            value: 'Sword',
            tooltip: 'Sword',
            image: (
              <StaticImage
                src="../../images/ww/icons/weapon_sword.png"
                width={24}
                alt="Sword"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.rarity.indexOf(activeFilters.rarity) > -1
        );
      }
      if (activeFilters.element && activeFilters.element !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.element.toLowerCase() === activeFilters.element.toLowerCase()
        );
      }
      if (activeFilters.weapon && activeFilters.weapon !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.weapon.toLowerCase() === activeFilters.weapon.toLowerCase()
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.name < b.name ? 1 : -1));
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [{ value: 'tower', label: 'Tower' }];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }
  return (
    <DashboardLayout
      className={'generic-page wuthering ww-tier-page'}
      game="ww"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/wuthering-waves/">Wuthering Waves</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/ww/categories/category_tier.jpg"
            alt="Tier list"
          />
        </div>
        <div className="page-details">
          <h1>Wuthering Waves Tier List</h1>
          <h2>
            The best tier list for Wuthering Waves that rates all available
            characters by their performance in the Tower of Adversity. Find the
            best characters in the game!
          </h2>
          <p>
            Last updated: <strong>13/02/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <div className="tier-list-page">
        <SectionHeader title="Wuthering Waves Tier list (2.1 Patch)" />
        <p className="intro">
          Please keep in mind Wuthering Waves is a{' '}
          <strong>
            skill-based game and each character has a different playstyle
          </strong>
          , difficulty level and even special tricks and combos. Wuthering Waves
          is also a team based game and it’s only by combining both these
          factors to the highest level a character can reach optimal
          performance. Our Wuthering Waves tier list was created with the help
          of various players who shared their thoughts about Wuthering Waves
          characters and their performance with us. If you want to learn more
          about the characters and why we rated them in that way, check their
          profiles -{' '}
          <strong>
            in the Review tab we have posted short reviews and pros & cons.
          </strong>
        </p>
        <Accordion className="tier-list-accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>About the Tier List</Accordion.Header>
            <Accordion.Body>
              <h6>Tower of Adversity</h6>
              <p>
                This tier list assesses characters based on their combat
                potential in{' '}
                <strong>
                  Wuthering Waves current most demanding content Tower of
                  Adversity (ToA)
                </strong>
                . ToA is a mode similar to Genshin Impact’s Spiral Abyss or
                Honkai Star Rail’s Memory of Chaos and requires multiple teams,
                strong Echoes and good Weapons. This mode offers a reasonable
                mix of Boss encounters (single target), Elite packs (2-3
                targets) and AoE scenarios (5+ Targets), allowing multiple
                characters to find a niche but in the end, there's a bias toward
                single target focused characters at the top-end.
              </p>
              <h6>ToA Vs Holograms</h6>
              <p>
                ToA is not the most mechanically demanding content in Wuthering
                Waves with high difficulty Holograms requiring much higher
                player skill levels. Holograms though can only offer their
                rewards once, can be completed with a singular unit, punish
                specific character kits and are exclusively single target. For
                these reasons we feel Tower of Adversity is a better
                representation of an ongoing meta in Wuthering Waves. As
                additional modes or rewards are added we will update the tier
                list or add variants as needed.
              </p>
              <h6>Skill Ceilings</h6>
              <p>
                As an action-combat game, Wuthering Waves offers many combat
                options to players - some of which are not easily executed but
                boost certain characters' powers substantially.{' '}
                <strong>
                  The Switch Cancel is an example of such a technique
                </strong>{' '}
                allowing you to switch out a character mid-attack to a new
                character while still fully executing the move you interrupted,
                essentially allowing you to attack with two characters at once.
                Such techniques won't be for everyone but a tier list must
                assess characters on their maximum potential and best teams.
                Each character is assessed with only the easiest and most
                powerful switch cancels included like Encore Rupture. Harder or
                more frequent cancels, such as Calcharo’s Death Messenger
                cancels, are not considered.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Criteria and roles</Accordion.Header>
            <Accordion.Body>
              <h6>Criteria</h6>
              <ul>
                <li>
                  All characters are assumed to be utilized in their full best 3
                  man team, played with optimal rotations without mistakes,
                  using best Echo main sets and correct Energy Regen values.
                </li>
                <li>
                  All characters are using maximum level gold rarity Echoes,
                </li>
                <li>
                  All Echo main stats are considered to be best in slot for that
                  characters build,
                </li>
                <li>
                  Each Echo used by characters is assumed to have 5 sub-stats
                  with average rolls,
                </li>
                <li>
                  Half of all sub stats (12) are designated as “good” sub stats
                  the character would desire where the remaining (13) are
                  random. This represent good but not insane endgame gear.
                </li>
                <li>
                  5★ characters are all rated at Sequence 0 unless otherwise
                  stated such as the Spectro (Rover) who starts at Sequence 6.
                  4★ characters at rated at Sequence 6.
                </li>
                <li>
                  All characters are assessed as if they have access to S1 of
                  all 4★ Weapons, S5 of all 2★ and 3★ Weapons and S1 of the
                  standard 5★ Weapons everyone will get their free choice of.
                  The best out of all these choices is used for their
                  assessment.
                </li>
                <li>
                  All characters are considered to be the maximum possible level
                  with all Fortes upgraded to maximum rank with a maximum level
                  Weapon.
                </li>
              </ul>
              <h6>Roles</h6>
              <p>
                We compare characters within their role - so DPS are compared to
                other DPS and the ratings are adjusted based on their
                performance within the role. Don't try to compare characters
                between roles, because their ratings mean different things.
              </p>
              <p>
                <strong className="dps">DPS</strong>{' '}
              </p>
              <p>
                DPS characters focus on being your sole damage dealers and the
                rest of your team is built to synergize with them and make them
                stronger. Stacking Echo set bonuses, character kit's and Outro
                Deepen bonuses together to power up a DPS is the goal of many
                teams. DPS character generally aim to spend roughly 14-15
                seconds on the field to consume all the team's buffs.
              </p>
              <p>Criteria that impact ratings for DPS:</p>
              <ul>
                <li>
                  Characters rotation difficulty and potential for mistakes,
                </li>
                <li>Character damage within best possible team,</li>
                <li>
                  Characters on-field flexibility and durability (can they
                  easily dodge during combo, will they die in 1 hit?),
                </li>
                <li>
                  Character ability to deal with all damage types - Single
                  target, Cleave and AoE but with a bias toward single target
                  being valued more.
                </li>
              </ul>
              <p>
                <strong className="specialist">Hybrid</strong>
              </p>
              <p>
                Hybrid characters directly support your damage dealer of choice,
                often with incredibly synergistic kits, specialized Outro buffs
                like “Deepen” multipliers or other beneficial effects. Almost
                always built to complete their rotation right before your DPS
                performs their full damage combo. Hybrid characters kits offer a
                diverse combination of supportive, offensive and sustaining
                capabilities and possess the most variety in gameplay and have
                many different niches. Most Hybrid characters prioritize
                generating their Concerto, Resonance energy, and getting their
                buffs up in as little on-field time as possible to maximize the
                field time of the damage dealer.
              </p>
              <p>Criteria that impact ratings for Hybrid:</p>
              <ul>
                <li>
                  Characters rotation difficulty and potential for mistakes,
                </li>
                <li>Character performance within best possible team,</li>
                <li>How many effective teams character is playable in,</li>
                <li>
                  Impact of kit on a teams performance (units that carry a team
                  rank higher),
                </li>
                <li>
                  Characters on-field flexibility and durability (can they
                  easily dodge during combo, will they die in 1 hit?),
                </li>
                <li>
                  Characters effective damage contribution after including their
                  buffs on the team,
                </li>
                <li>Total on-field time required to perform rotation.</li>
              </ul>
              <p>
                <strong className="amplifier">Support</strong>
              </p>
              <p>
                Support characters must have the capability to provide benefit
                to the team regardless of the order in which they are switched
                in, so as not to conflict with the majority of Hybrid characters
                outro’s only buffing the next character to enter. Supports aim
                to provide maximum buffs, utility, defenses and healing in as
                little field time as possible to maximize the capabilities of
                your damage dealer foremost but also your hybrid character.
              </p>
              <p>Criteria that impact ratings for Supports:</p>
              <ul>
                <li>
                  Characters rotation difficulty and potential for mistakes,
                </li>
                <li>Character performance within best possible teams,</li>
                <li>How many effective teams character is playable in,</li>
                <li>
                  Impact of kit on a teams performance (units that carry a team
                  rank higher),
                </li>
                <li>
                  Characters on-field flexibility and durability (can they
                  easily dodge during combo, will they die in 1 hit?),
                </li>
                <li>
                  Characters effective damage contribution after including their
                  buffs on the team,
                </li>
                <li>Total on-field time required to perform rotation.</li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Tags</Accordion.Header>
            <Accordion.Body>
              <h6>Special tags</h6>
              <p>
                Tags represent the most defining features of a character's kit.
              </p>
              <ul>
                <li>
                  <strong className="archetype">ST</strong> - Character deals
                  mostly single target damage to enemies but can hit multiple
                  enemies if they are stacked directly on top of each other.
                </li>
                <li>
                  <strong className="archetype">Cleave</strong> - Character has
                  a mix of smaller range cleaving attacks or attacks that strike
                  in a radius around them but does not consistently hit all
                  enemies in the screen with the majority of their rotation.
                </li>
                <li>
                  <strong className="archetype">AoE</strong> - A large portion
                  of these characters damage or benefit is in the form of pure
                  AoE hitting most if not all enemies on the battlefield.
                </li>
                <li>
                  <strong className="pro">Heal</strong> - Character can heal
                  themselves or the team, providing sustain but also triggering
                  the 5-P Rejuvenating Glow set bonus.
                </li>
                <li>
                  <strong className="pro">Shield</strong> - Character can apply
                  shields to themselves or the entire team and/or provide
                  defensive buffs and benefits further increasing the
                  survivability.
                </li>
                <li>
                  <strong className="pro">Coord</strong> - Character can perform
                  Coordinated Attacks consistently, thanks to a major portion of
                  their kit providing damaging, supporting or utility benefits
                  while not on the field. Or the character can make use of
                  Coordinated Attacks to enhance their own kit.
                </li>
                <li>
                  <strong className="pro">Multirole</strong> - When built in an
                  alternative way, can move to a different category on the tier
                  list and flex their role to another position even if it’s less
                  conventional.
                </li>
                <li>
                  <strong className="cons">Cancel</strong> - Character needs or
                  is widely considered to be far more powerful with the use of
                  “swap cancelling” to enhance their performance.
                </li>
                <li>
                  <strong className="cons">Ult</strong> - Character is
                  ultimate-centric with most of their damage occurring during an
                  ultimate window, creating a reliance on it. Without the
                  ultimate active character feels lackluster.
                </li>
                <li>
                  <strong className="special">Expert</strong> - Characters
                  tagged with the Expert Tag can be considered one placement
                  higher than their tier list ranking, but you need to master
                  their advanced/harder playstyle for them to qualify for that
                  higher rating.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              Changelog
              <span className="tag new">Updated!</span>
            </Accordion.Header>
            <Accordion.Body>
              <h6>13/02/2025</h6>
              <p>List of changes:</p>
              <ul>
                <li>
                  <strong>Phoebe</strong> added to the tier list (T0 DPS). We'll
                  put it as bluntly as we can: Phoebe is the best F2P DPS in the
                  game. With a weapon and teammates that don't even require you
                  to do a single pull, she performs less than half a tier below
                  Jinhsi with her premium weapon and supports. And then she can
                  get improved with better weapons and quickswap.
                </li>
                <li>
                  <strong>Rover (Spectro)</strong> T3{' '}
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T1.5. Spectro Rover is the first and only character we've ever
                  had that got their kit buffed by Kuro themselves — they can
                  now apply Spectro Frazzle, and a ton of it! This change alone
                  wasn't much in 2.0; but that now makes them mandatory to
                  Phoebe teams. Phoebe is one of the best DPS characters we ever
                  had — judging how Mortefi who is also restricted
                  teambuilding-wise is T1, we deem T1.5 fair for Spectro Rover
                  (Mortefi being unlikely to drop due to his use in Phoebe,
                  Jiyan and other teams). We also added the Multirole tag as
                  they can sport both the Rejuvenating Glow and Moonlit Clouds
                  sets very efficiently.
                </li>
                <li>
                  <strong>Rover (Havoc)</strong> T1{' '}
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T0.5. Havoc Rover finally got access to their S6 for free in
                  2.1 with the main story, and with it comes a free 25% CRIT
                  Rate boost — just for comparison purposes, that's more than a
                  high base ATK 5-star sword, and that's more than a max level 4
                  cost main stat Echo. Needless to say the buff is huge. It's in
                  fact enough to propel them up to T0.5. That being said, they
                  are put on Watchlist to test how they compare with Camellya,
                  in the instance she is placed in T0.5 as well.
                </li>
                <li>
                  <strong>Lingyang</strong> T2{' '}
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T3. We wanted to drop him sooner, but tried to test Lingyang
                  more extensively in 2.0 due to the addition of the Frosty
                  Resolve set — we tried to optimize his rotations too. Sure
                  enough, it helped a tiny bit. Did it help enough to make him
                  keep up with Calcharo and Chixia though? Unfortunately not.
                  Hence he's dropping to T3. New baseline and optimized
                  rotations coming soon though!
                </li>
              </ul>
              <p>
                We had a lot of changes planned for our 2.1 Tier List update.
                Some changes went through to go along with Phoebe's release.
                When it comes to her and her teammates, we are fairly certain of
                our placements, so her pull value should remain unchanged from
                what is seen in this new edition of our Tier List. However, we
                face a new problem: two thirds of our DPS category is in our 2
                top tiers, everyone else is in the 2 bottom tiers. We noticed a
                clear discrepancy in power — and some characters are inferior to
                others within their current tiers, which we'll explain below.
                This made us want to consider cascading the tier list down: as a
                result, we added a Watchlist icon. Everyone who's on our current
                watchlist is suspected to drop by one tier. These changes may go
                through for some characters, or it won't for others. We ask for
                your feedback and will do extensive testing to decide how we
                proceed about the Tier List, all updates going through with
                Brant's release on the second half of Patch 2.1.
              </p>
              <p>
                We appreciate anyone who sends feedback our way, make sure to
                join our Discord to do so!
              </p>
              <ul>
                <li>
                  Baizhi - placed on Watchlist to cascade in relation to Spectro
                  Rover, who can also fill the role of support and is especially
                  stronger in Phoebe teams but is currently placed a tier lower.
                </li>
                <li>
                  Camellya - Camellya is our main point of discussion on the
                  whole Watchlist. She determines a ton of stuff — we consider
                  placing her alone in T0.5, above anyone but Jinhsi, Carlotta
                  and Phoebe. The reasoning? Inconsistency — Camellya needs to
                  hit her attacks for her rotations to be smooth. Against a
                  moving target, almost any enemy in the game, this isn't
                  happening. And the second this doesn't happen, her rotations
                  become longer and awkward. We factor in difficulty when
                  considering our Tier List placements, but Camellya
                  fundamentally isn't difficult to play. The issue is simply
                  that she doesn't reliably hit all her attacks against all
                  enemies. So missing with Camellya is a HUGE issue which isn't
                  exclusive to casuals — sure, on paper she sheets about the
                  same as Jinhsi. In practice though? It's easy to observe
                  there's little comparison to be made — in fact, after
                  extensive testing and mathing, we found it joins Havoc Rover
                  S6 more so than Jinhsi. So we wonder if we should drop her by
                  herself in T0.5 for that, every other DPS going down half a
                  tier as a consequence.{' '}
                </li>
                <li>
                  Changli - We've been considering dropping Changli for a long
                  while now — we're unsure of what to do with her, since for
                  casual players who aren't quickswapping constantly she would
                  land around T1. That being said, for expert players, she's
                  undeniably T0 and our expert tag doesn't cover a discrepancy
                  of two tiers. So she's placed on Watchlist as we're
                  considering simply dropping her to T1 to cater to our core
                  casual audience and specify in her Review and Pros & Cons page
                  that she's a T0 at Expert level.{' '}
                </li>
                <li>
                  Encore - Placed on Watchlist to cascade with Camellya & Jiyan
                  — Jiyan and Encore go in the same tier as their singletarget
                  performance is identical. Jiyan only has the edge in AoE but
                  this barely matters for Tower of Adversity as it's mostly
                  boss-focused. If Jiyan drops because of Camellya, Encore drops
                  too.{' '}
                </li>
                <li>
                  Jiyan - Placed on Watchlist to cascade with Camellya - his
                  damage performance simply doesn't match hers (to be expected
                  as Jiyan is a 1.0 character). So if Camellya drops, we think
                  he should drop a tier too.{' '}
                </li>
                <li>
                  Xiangli Yao - Placed on Watchlist to cascade with Camellya —
                  at a casual level he's undoubtedly worse, but at an expert
                  level with someone like Changli he catches up. So we're
                  considering dropping him but giving him the Expert tag.{' '}
                </li>
                <li>
                  Yinlin - Placed on Watchlist to cascade with Xiangli Yao —
                  Xiangli Yao is the best character that wants Yinlin as his
                  best Hypercarry Hybrid, as she's outclassed by Zhezhi in
                  Jinhsi teams. For this reason, if Xiangli Yao drops a tier, we
                  think she should drop a tier too.{' '}
                </li>
                <li>
                  Youhu - Placed on Watchlist to cascade in relation to Baizhi,
                  who's significantly more flexible than Youhu while retaining
                  very similar strength in optimal Youhu teams.
                </li>
              </ul>
              <h6>23/01/2025</h6>
              <p>List of changes:</p>
              <ul>
                <li>
                  <strong>Roccia</strong> added to the tier list (T1 Hybrid).
                  Roccia is a specialist focused on buffing specifically Havoc
                  Basic Attacks. This means Roccia, while strong, is dedicated
                  to a few specific teams the main ones being Camellya’s and
                  Havoc Rover’s. Unfortunately for Roccia, she faces stiff
                  competition in both from S6 Sanhua who does a similar job but
                  can also play within far more meta team compositions. As a
                  result of this, Roccia lands herself in T1 for now. With that
                  said it’s worth noting Roccia is an AoE gathering monster -
                  endgame is currently very single target focused but in the
                  future that may change. When that happens Roccia is likely to
                  ascend the tiers as she gains an edge over her competitors.
                </li>
                <li>
                  <strong>Yangyang</strong> T2{' '}
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T1.5. Yangyang has seen a substantial increase in use recently
                  - thanks to the addition of Carlotta, who she's proven to
                  perform strongly with being one of the best F2P options for
                  her. This on top of her previous viability in quickswap teams
                  with either Xiangli Yao or Changli has earned her an increase
                  in rating.
                </li>
                <li>
                  <strong>Mortefi</strong> T0.5{' '}
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T1. Mortefi has been at the top of our tier list since 1.0 and
                  for good reason - when combined with Jiyan he formed the
                  backbone of one of the strongest teams which still stands
                  today. However with so many diverse damage dealers, and more
                  meta viable teams than ever available, Mortefi still only
                  works at the highest level with Jiyan. For this reason we’re
                  lowering his tier for now, but expect him to be right back
                  when we get more Heavy Attackers for him to play with, which
                  should be soon.
                </li>
                <li>
                  Added Multirole tag to <strong>Changli</strong>. Added to
                  better reflect her preferable playstyle with more casual
                  players who aren't necessarily going to quickswap often.
                </li>
              </ul>
              <h6>02/01/2025</h6>
              <p>List of changes:</p>
              <ul>
                <li>
                  <strong>Carlotta</strong> has been added to the tier list. The
                  numbers and testing we’ve done shows that she’s one of the
                  best DPS characters in the game who is also very easy to use.
                  As a result we decided to put her immediately into the T0 row.
                </li>
                <li>
                  <strong>Zhezhi</strong> T0.5{' '}
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T0. Already argued by some as being that strong prior to
                  Carlotta’s release, Zhezhi proved herself in version 2.0 as
                  the best meta Hybrid option for arguably the two best DPS
                  characters in the game — Jinhsi and Carlotta. Her brand new
                  Jump Cancel tech found by our theorycrafting team additionally
                  optimizes her into a Hybrid with a very short rotation - if
                  players are willing to push Zhezhi to her limits. We’re moving
                  her up to T0 to reflect how wanted she is by the top team
                  compositions in the game along with her impressive base
                  strength.
                </li>
                <li>
                  <strong>Encore</strong> T1{' '}
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T0.5. After further testing from our theorycrafting team,
                  we’ve found out Encore’s single-target DPS matches Jiyan’s -
                  who’s present in the tier previously above hers. Both able to
                  be optimized, but also being more than playable in their best
                  teams at a casual level - in either case their single-target
                  DPS ends up matching as evenly as it gets. Their only
                  difference is that Encore is exclusively single-target while
                  Jiyan has huge AoE capabilities. This means Jiyan remains
                  overall slightly stronger, but very comparable to her.
                </li>
                <ul>
                  <li>
                    In this logic, we’re removing the Expert tag on Encore and
                    moving her up to T0.5 — her optimized single-target DPS
                    matches the characters in that tier, but we deem the tag
                    unnecessary as it’s not present on Jiyan. And Encore’s best
                    team is in actuality quite easy to play at a casual level -
                    where she still deals about as much DPS as a casual level
                    Jiyan, justifying her moving up.
                  </li>
                </ul>
                <li>
                  <strong>Rover (Havoc)</strong> T1.5{' '}
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T1. After a bunch of optimization both to Havoc Rover and
                  their best support - Danjin, we’ve found out their DPS, even
                  at a casual level, is certainly lagging behind the T0.5
                  characters, but not enough to justify a big gap between Havoc
                  Rover and the high tier DPS characters like Encore, Jiyan and
                  Xiangli Yao. To reflect the big DPS increases our new
                  discoveries caused Havoc Rover to obtain, we’re moving them up
                  to T1 to more accurately reflect the surprisingly impressive
                  amount of damage they can deal. Keep in mind their position is
                  still subject to change once we can acquire their last 3
                  Sequences, which might push them up even higher.
                </li>
                <li>
                  <strong>Youhu</strong> T1{' '}
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T1.5. After further testing on our end, we’ve found out Youhu
                  holds a very small niche in the meta — only being a good
                  Support option if you don’t have Shorekeeper on your account
                  (as pretty much every account should have Verina and Baizhi by
                  now). Lagging behind at least slightly in regards to all three
                  generalist healer girls mentioned prior, she treads a very
                  fine line between remaining relevant and going completely
                  under the radar. Judging as she’s still quite far from a
                  terrible character, we’re moving her down to T1.5 to reflect
                  her situation.
                </li>
                <li>
                  <strong>Lumi</strong> T2{' '}
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T3. We were already hesitant on placing Lumi in T3 on her
                  release, but with further testing, we’ve found out she holds
                  no realistic meta niche and her meta utility is comparable to
                  that of Taoqi’s. We tried making some Main DPS Lumi teams
                  work, but they had and still have no commendable success
                  whatsoever. Unfortunately lagging behind pretty much any other
                  options, she’s being moved in the bottom tier to illustrate
                  that. We will see if she will increase her value with
                  Carlotta's release as she can be used there as a budget
                  replacement to Zhezhi, but for now she will stay in T3.
                </li>
                <li>
                  Moved <strong>Yuanwu</strong> to the Support category.
                  Yuanwu’s only realistic use case was as a budget option for
                  Jinhsi teams. Outside of that, he holds no niche in the meta,
                  explaining his tier placement. Following this logic, as Yuanwu
                  is always sporting the Rejuvenating Glow set and a 3 star
                  Originite weapon as it’s most optimal on him in Jinhsi teams,
                  we think he currently fits a Support more so than a Hybrid.
                </li>
                <li>Added new tags to multiple characters.</li>
              </ul>
              <h6>12/12/2024</h6>
              <p>List of changes:</p>
              <ul>
                <li>
                  <strong>Lumi</strong> has been added to the tier list,
                </li>
                <li>Added new tags to multiple characters:</li>
                <ul>
                  <li>
                    <li>
                      Added Expert tag to <strong>Yangyang</strong>,
                    </li>
                    <li>
                      Added Multirole tag to <strong>Aalto and Danjin</strong>,
                    </li>
                    <li>
                      Added Coord tag to <strong>Baizhi</strong>,
                    </li>
                    <li>
                      Added Heal tag to <strong>Rover (Spectro)</strong>,
                    </li>
                  </li>
                </ul>
                <li>
                  Moved <strong>Rover (Spectro)</strong> to the Support
                  category.
                </li>
              </ul>
              <h6>03/12/2024</h6>
              <p>
                In this tier list update we’re adjusting a number of character
                categories to reflect their most competitively played role in
                endgame with S6 in mind. We’re also expanding the selection of
                characters marked with the Expert tag and removing the Risk tag
                as the newer Expert tag makes it redundant. Thank you for all
                the feedback send our way, especially{' '}
                <a
                  href="https://www.youtube.com/@e-thin"
                  target="_blank"
                  rel="noreferrer"
                >
                  E-Thin
                </a>
                ,{' '}
                <a
                  href="https://www.youtube.com/@GachaSmack07"
                  target="_blank"
                  rel="noreferrer"
                >
                  Gacha Smack
                </a>
                , Serra, Sweat and Thundah.
              </p>
              <ul>
                <li>
                  <strong>Danjin</strong>: Danjin has value both thanks to her
                  damage output and buffs at S6, so we’re moving her to the
                  Hybrid category to better reflect her not simply being a pure
                  damage dealer. Also as mentioned above we’re exchanging her
                  Risk tag for the Expert tag instead.
                </li>
                <li>
                  <strong>Changli</strong>: We’re adding the Expert tag to
                  Changli in recognition of her more challenging playstyle when
                  compared to other characters. As a result we’re lowering her
                  placement half a tier for the average player but as per the
                  Expert tag for those who can play her optimally her ranking
                  remains unchanged - basically Changli floor is already at a
                  very decent level, but her ceiling can only be reached if you
                  know what to do.
                </li>
                <li>
                  <strong>Yangyang & Jianxin</strong>: With S6 (for Yangyang)
                  and new teams in mind, Jianxin and Yangyang are moving into
                  the Hybrid category to better reflect their position in the
                  compositions and playstyles of the current meta.
                </li>
                <ul>
                  <li>
                    Yangyang calculations and rotations will be updated soon to
                    reflect that.
                  </li>
                </ul>
                <li>
                  <strong>Aalto</strong>: Following up on our previous update
                  we’re moving forward with shifting Aalto into the Damage
                  category. As a Hybrid he currently has no team - at best he is
                  a backup for Jiyan if Mortefi isn’t an option. At S6 he has an
                  identity as a damage dealer so we’re moving him there for now.
                  Though, he may find himself returning to Hybrid with the
                  release of new Aero Damage dealers.
                </li>
                <li>
                  <strong>Yuanwu & Spectro Rover</strong>: With Yangyang and
                  Jianxian moving into the Hybrid category, we’re adjusting the
                  rank of Yuanwu and Spectro Rover accordingly. Neither Yuanwu
                  nor Spectro Rover have high meta relevance - Yuanwu has a
                  niche position in Jinhsi teams but sits below many other
                  better alternatives. And Spectro Rover sees little use due to
                  their overlap with the far superior Havoc Rover. For these
                  reasons we’re positioning them a tier below the newly moved
                  Yangyang and Jianxin for now.
                </li>
                <li>
                  <strong>Mortefi & Zhezhi</strong>: We’re aware some players
                  feel Mortefi and Zhezhi are rated too highly based on the fact
                  they’re “One Team Wonders” - only really serving as heavy
                  hitters in Jiyan and Jinhsi teams respectively. Despite this
                  we feel the teams these characters sit within are strong
                  enough to justify holding onto their rating for now, but with
                  that in mind we’ll be keeping an eye on them moving into 2.0.
                </li>
              </ul>
              <h6>18/11/2024</h6>
              <p>
                In our previous update we asked for your feedback on a few
                characters and based on that we’re making some additional
                updates to the tier list:
              </p>
              <ul>
                <li>
                  <strong>Camellya</strong> T0.5
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T0. After a few more days of testing, we’re updating her
                  rating from Tier 0.5 to Tier 0. When measuring Camellya’s kit
                  and numbers against other top tier characters, she isn’t a
                  step above her competition - she’s quite similar (in a
                  vacuum). However, Camellya (and her teams), unlike a lot of
                  other characters is quite accessible, making achieving her
                  full potential not only possible but fairly easy. Based on
                  this, Camellya ends up performing better than her competition
                  in T0.5 and closer to what Jinhsi offers - because of that
                  we’re raising her to Tier 0 alongside her. Also, a different
                  rotation was found that improves her single target damage
                  output and we're currently testing it - once we're done, the
                  calcs on site will be updated. Stay tuned!
                </li>
                <li>
                  <strong>Changli</strong> T0.5
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T0 and moved to Hybrid category. We’ve gathered and evaluated
                  all your feedback on which category the community feels
                  Changli should be placed in. As a result we’ve altered her
                  category from Main DPS to Hybrid whilst also raising her into
                  Tier 0 to better fit her position in the meta relative to
                  other characters in this category. We’ll continue to monitor
                  how Changli is being played in future patches and with
                  additional character releases as to whether she’ll make a
                  return to the Main DPS category.
                </li>
                <li>
                  <strong>Yinlin</strong> T0
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T0.5. Yinlin is a character we categorized on the edge of Tier
                  0 and Tier 0.5 at the time of our rework. She's able to be
                  played in many of the top ToA teams with ease, but always with
                  options for substitution (Xiangli Yao, Jinhsi, etc). With the
                  move of Changli to Hybrid Tier 0 we’re moving her from one
                  edge to another and placing her into Tier 0.5 for now.
                </li>
              </ul>
              <h6>14/11/2024</h6>
              <ul>
                <li>
                  <strong>Camellya</strong> has been added to the tier list.
                </li>
              </ul>
              <p>
                As promised in the last update, we have revamped the tier list
                with the addition of 4★ characters set at Sequence 6. For some
                4★ the update to Sequence 6 is a substantial one - for others it
                has less impact. Some 4★ characters have moved up in the Tier
                List while others positions have only improved relative to other
                5★ characters with a similar role. Also this update is a general
                parse on the meta resulting in some characters moving position
                regardless of additional Sequences.
              </p>
              <p>
                Additionally as a part of this update we’re also introducing a
                new Tag alongside removing some old ones to address the ranking
                of specific characters we’ve had trouble placing in the past.
              </p>
              <p>
                Our Tier Lists aims to reflect not only in-game testing and
                performance but also to ideally align with community opinion and
                experience. If you have any feedback on the updated Tier List
                don’t hesitate to reach out and share - we’re always listening
                and ready to make changes as needed!
              </p>
              <p>
                <strong>The Expert Tag:</strong> Internally we set an
                expectation of what we consider a reasonable level of play when
                writing guides and assessing characters performance. Sometimes
                characters' optimal play styles fall outside these expectations,
                resulting in a lower than expected placement on our Tier List
                but with evidence of extraordinary performance from
                dedicated/skilled community members.{' '}
                <strong>
                  Our goal is to represent the value of a character to the
                  average player but we also agree a character's maximum power
                  must be recognized.
                </strong>{' '}
                The Expert Tag is our solution to this. Characters tagged with
                the Expert Tag can be considered one placement higher than their
                tier list ranking - we’ve attempted something similar in the
                past but we’re now making it far more obvious by branding the
                character's portrait with a special icon representing this
                higher placement. We hope dedicated players of these characters
                understand the lower placement, but with the recognition of
                potential with mastery of the character.
              </p>
              <p>
                <strong>[Encore & Calcharo]</strong> Encore and Calcharo are the
                two characters at the time of rework we are attaching the Expert
                Tag to. Encore in the hands of most players is a solid Resonator
                with good Single Target and Cleave damage output boasting a
                relatively simple playstyle and rotation. However, in the hands
                of the dedicated Encore players, she becomes far more - she
                becomes highly technical, far harder to play, but also much
                stronger. She is the driving force behind the introduction of
                this special tag. Calcharo, similar to Encore, has a baseline
                performance much lower than his maximum potential and for all
                the same reasons we’re attaching the tag to him as well.
              </p>
              <p>
                When reworking the Tier List two characters were the subject of
                much discussion, Changli and Aalto (S6). As a result of these
                discussions we’re placing both characters on our Watch List with
                the intention of hearing what you have to say on these
                characters before proceeding with any changes.
              </p>
              <ul>
                <li>
                  <strong>[Changli]</strong> Compared to Camellya, Xiangli Yao
                  and Jiyan, Changli doesn’t feel placed quite right. She has no
                  meta defining “best” team when compared to these characters at
                  this time and usually makes do with a non-perfect fit, hybrid
                  character, double DPS Quick-Swap set-up or double Support
                  team. Changli has fantastic potential but feels limited by
                  teammate choices - she sometimes serves as a Hybrid in the
                  Sub-DPS role and doesn’t feel like she has quite the meta
                  foothold the aforementioned 3 have. We are also considering
                  rating her as both Main DPS and Hybrid and she would then
                  appear in both columns with different ratings - do let us know
                  what you think of the idea.
                </li>
                <li>
                  <strong>[Aalto]</strong> Aalto currently has no place in any
                  team as a supportive character. Our only Aero damage character
                  is Jiyan and Mortefi has proven to be the superior choice in
                  his team. Until an Aero character for Aalto to support is
                  released we expect him to continue to struggle as a Hybrid
                  Support/DPS character. At S6 Aalto gains substantial damage
                  potential with many considering him to be more of a main DPS.
                  Our team is currently considering moving Aalto to the Pure DPS
                  category until such a time a character he can support is
                  released.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="fuse-ad-placeholder" data-fuse="22844297241"></div>
        <div className="tier-list-header">
          <h2 className="title">
            Wuthering Waves Tier List <span>(Tower of Adversity)</span>
          </h2>
          <h3 className="sub-title">
            You're currently viewing the Wuthering Waves{' '}
            <strong>Tower of Adversity</strong> tier list. It shows how the
            character performs in the mode and how easy time they will have
            clearing it.
          </h3>
        </div>
        <div className="employees-filter-bar ww">
          <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
        </div>
        <div className="custom-tier-list-ww">
          <div className="custom-tier-header">
            <div className="tier-rating">
              <span>&nbsp;</span>
            </div>
            <div className="custom-tier-container">
              <div className="custom-header dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-header debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Hybrid
              </div>
              <div className="custom-header support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
            </div>
          </div>
          <div className="custom-tier tier-0 first">
            <div className="tier-rating t-0">
              <span>T0</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 11)
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Hybrid
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 11)
                  .filter((emp) => emp.tierCategory === 'Hybrid')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 11)
                  .filter((emp) => emp.tierCategory === 'Support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-05">
            <div className="tier-rating t-05">
              <span>T0.5</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 10)
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Hybrid
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 10)
                  .filter((emp) => emp.tierCategory === 'Hybrid')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 10)
                  .filter((emp) => emp.tierCategory === 'Support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-1">
            <div className="tier-rating t-1">
              <span>T1</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 9)
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Hybrid
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 9)
                  .filter((emp) => emp.tierCategory === 'Hybrid')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 9)
                  .filter((emp) => emp.tierCategory === 'Support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-15">
            <div className="tier-rating t-15">
              <span>T1.5</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 8)
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Hybrid
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 8)
                  .filter((emp) => emp.tierCategory === 'Hybrid')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 8)
                  .filter((emp) => emp.tierCategory === 'Support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-2">
            <div className="tier-rating t-2">
              <span>T2</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 7)
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Hybrid
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 7)
                  .filter((emp) => emp.tierCategory === 'Hybrid')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 7)
                  .filter((emp) => emp.tierCategory === 'Support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-3">
            <div className="tier-rating t-3">
              <span>T3</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 6)
                  .filter((emp) => emp.tierCategory === 'DPS')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Hybrid
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 6)
                  .filter((emp) => emp.tierCategory === 'Hybrid')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 6)
                  .filter((emp) => emp.tierCategory === 'Support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297241"></div>
      </div>
    </DashboardLayout>
  );
};

export default WWTierList;

export const Head: React.FC = () => (
  <Seo
    title="Tier List | Wuthering Waves | Prydwen Institute"
    description="The best tier list for Wuthering Waves that rates all available characters by their performance in the Tower of Adversity. Find the best characters in the game!"
    game="ww"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulWwCharacter(
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        rarity
        element
        weapon
        tierCategory
        tierTags
        ratings {
          tower
        }
        isNew
      }
    }
  }
`;
